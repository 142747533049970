import React, { useRef } from 'react';
import { useCollapse } from '@hooks/useCollapse';

interface CollapseFaqProps {
  question: string;
  answer: string;
  isOpened?: boolean;
}
export const CollapseFaq = ({
  question,
  answer,
  isOpened = false,
}: CollapseFaqProps) => {
  const content = useRef<HTMLDivElement>(null);

  const { isOpen, height, toggle } = useCollapse(isOpened, content);

  return (
    <div className="mt-3 rounded-3xl bg-white shadow-light">
      <div
        onClick={toggle}
        className={`group flex w-full cursor-pointer items-center justify-between rounded-2xl py-4 px-4 transition-colors duration-300 hover:bg-text hover:text-white lg:rounded-3xl lg:px-10 ${
          isOpen ? 'bg-text text-white' : 'bg-white text-text'
        }`}
      >
        <h3 className="text-sm font-bold lg:text-xl">{question}</h3>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="6.888"
          height="11.416"
          viewBox="0 0 6.888 11.416"
          className={`ml-2 h-3 w-3 fill-text transition-all duration-300 group-hover:fill-white ${
            isOpen ? '-rotate-90 fill-white' : 'rotate-90 fill-text'
          }`}
        >
          <path
            id="caret-right-solid"
            d="M65.959,96.325l4.592,4.56a1.25,1.25,0,0,1,.337.811,1.2,1.2,0,0,1-.336.812l-4.592,4.56a1.148,1.148,0,0,1-1.251.249A1.074,1.074,0,0,1,64,106.287V97.136a1.146,1.146,0,0,1,1.959-.811Z"
            transform="translate(-64 -95.987)"
          />
        </svg>
      </div>
      <div
        ref={content}
        className={`max-h-0 overflow-hidden rounded-2xl !rounded-tl-none bg-white transition-all duration-500`}
        style={{ maxHeight: height }}
      >
        <p className="animate-fade py-4 px-4 text-sm lg:px-10">{answer}</p>
      </div>
    </div>
  );
};
