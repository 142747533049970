import Button from '@components/button/button';
import { CollapseFaq } from '@components/collapse-faq/collapse-faq';
import SectionTag from '@components/section-tag/section-tag';
import React from 'react';
import config from '../../../config.json';

const faqData = [
  {
    question: 'Co to jest ubezpieczenie na życie?',
    answer:
      'Polisa na życie to wsparcie finansowe dla rodziny w sytuacji śmierci ubezpieczonego. To również ochrona zdrowia rodziny, pomoc finansowa w przypadku nieszczęśliwych zdarzeń oraz możliwość systematycznego oszczędzania pieniędzy na dowolny cel.',
    isOpen: false,
  },
  {
    question: 'Ile kosztuje ubezpieczenie dla dziecka?',
    answer:
      'Koszty ubezpieczenia na życie zależą od jego zakresu, liczby ubezpieczonych i ich wieku. Najtańsze ubezpieczenie na życie to koszt ok. 40 zł miesięcznie.',
    isOpen: false,
  },
  {
    question: 'Jak wybrać ubezpieczenie dla dziecka?',
    answer:
      'Wybierając ubezpieczenie dla dziecka, kieruj się jego potrzebami na danym etapie dorastania oraz swoimi możliwościami finansowymi. Jeśli masz jakiekolwiek wątpliwości związane z polisą dla dziecka, wypełnij krótki formularz na naszej stronie, a specjalista skontaktuje się z Tobą i pomoże wybrać najkorzystniejszą dla Ciebie ofertę.',
    isOpen: false,
  },
  {
    question: 'Jak ubezpieczyć dziecko?',
    answer:
      'W zależności od rodzaju polisy możesz ubezpieczyć dziecko, kupując odpowiedni pakiet na stronie towarzystwa ubezpieczeniowego, korzystając  z usług multiagencji, lub całkowicie zdalnie, zamawiając rozmowę z agentem ubezpieczeniowym.',
    isOpen: false,
  },
];

export default function Faq() {
  return (
    <div className="relative w-full overflow-hidden bg-main py-6 pt-10 lg:py-14">
      <div className="container relative z-10 mx-auto px-4">
        <div className="">
          <SectionTag tag="span">FAQ</SectionTag>
          <h2 className="mt-6 text-left text-[32px] font-black leading-snug text-text lg:text-left lg:text-[42px] lg:leading-tight">
            Najczęściej zadawane pytania
          </h2>
          <p className="mt-6 max-w-xl text-sm lg:text-lg">
            Ubezpieczenia dla dziecka to dla Ciebie czarna magia? Zacznij tutaj
            – przeczytaj odpowiedzi na najpopularniejsze pytania. A jeżeli wciąż
            będziesz głodny wiedzy, przejdź do poradników – nasi specjaliści
            chętnie dzielą się tam swoją wiedzą.
          </p>
        </div>
        <div className="mt-10">
          {faqData.map((faq, index) => (
            <CollapseFaq key={index} {...faq} />
          ))}
        </div>
      </div>
    </div>
  );
}
