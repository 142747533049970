import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '@components/layout/layout';
import Seo from '@components/seo/seo';
import Hero from '@domain/hero/hero';
import { Calculator } from '@components/calculator/calculator';
import BgCalc from '@img/bg-calc1.svg';
import FewSteps from '../domain/few-steps/few-steps';
import GoodStart from '@domain/good-start/good-start';
import { GetMore } from '@domain/get-more/get-more';
import { Benefits } from '@domain/benefits/benefits';
import { TrustedBrand } from '@domain/trusted-brand/trusted-brand';
import Faq from '@domain/faq/faq';
import BgPink from '@img/bg-pink.svg';
import Guides from '@domain/guides/guides';

const IndexPage = ({ data }: any) => {
  const wpPage = data.wpPage;
  const posts = data.allWpPost.edges;
  return (
    <>
      <Layout>
        <Seo
          title={wpPage.seo.title}
          description={wpPage.seo.metaDesc}
          keywords={wpPage.seo.focuskw}
          pathname="/"
        />
        <Hero />
        <div
          className="relative z-20 -mt-16 w-full !bg-right pb-10 pt-16 lg:-mt-12 lg:!bg-center xl:pb-24 xl:pt-24"
          style={{
            background: `url(${BgCalc})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <div className="container relative z-20 mx-auto px-4 ">
            <Calculator />
          </div>
        </div>
        <FewSteps />
        <GoodStart />
        <div className="relative z-20 bg-transparent">
          <GetMore />
        </div>
        <div
          className="relative -mt-80 w-full  bg-center lg:-mt-44 lg:bg-top"
          style={{
            background: `url(${BgPink})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <div className="container relative z-20 mx-auto px-4 pt-72 lg:pt-32">
            <Benefits />
          </div>
        </div>

        <TrustedBrand />
        <Faq />
        <Guides posts={posts} />
      </Layout>
    </>
  );
};

export const query = graphql`
  {
    allWpPost(
      sort: { order: DESC, fields: dateGmt }
      filter: {
        categories: {
          nodes: {
            elemMatch: {
              slug: {
                in: ["pozostale", "bezpieczenstwo", "edukacja", "oszczedzanie"]
              }
            }
          }
        }
      }
      limit: 6
    ) {
      edges {
        node {
          dateGmt(formatString: "DD MMMM YYYY", locale: "pl")
          id
          title
          excerpt
          slug
          featuredImage {
            node {
              altText
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 750, height: 400, quality: 90)
                }
              }
            }
          }
          modifiedGmt(formatString: "DD.MM.YYYY")
          zajawkaBlog {
            zajawka
          }
          content
          categories {
            nodes {
              slug
              name
            }
          }
        }
      }
    }
    wpPage(slug: { eq: "strona-glowna" }) {
      seo {
        metaDesc
        title
        focuskw
      }
    }
  }
`;

export default IndexPage;
