import Button from '@components/button/button';
import Heading from '@components/heading/heading';
import SectionTag from '@components/section-tag/section-tag';
import React from 'react';
import config from '../../../config.json';
import OffersSvg from '@img/offers.svg';
import ConsultationsSvg from '@img/consultations.svg';
import InsuredSvg from '@img/insured.svg';
import UnderscoreSvg from '@img/underscore.svg';

export const TrustedBrand = () => {
  return (
    <div className="w-full bg-secondaryLight ">
      <div className="container mx-auto px-4 py-6 text-center lg:py-20">
        <div className="flex flex-col items-center justify-center">
          <SectionTag tag="span">ZAUFANA MARKA</SectionTag>
          <Heading tag="h2" className=" !leading-snug ">
            Pomogliśmy już <span className="text-secondary">1246</span> osobom
            zabezpieczyć ich życie
          </Heading>
        </div>
        <div className="mx-auto mt-10 grid max-w-5xl grid-cols-1 gap-6 lg:grid-cols-3 lg:gap-16">
          <div
            className={`flex w-full cursor-pointer flex-col items-center rounded-3xl px-4 py-6 `}
          >
            <img src={OffersSvg} alt="" />
            <span className="relative mt-4 text-6xl font-black text-secondary">
              2156
              <img src={UnderscoreSvg} alt="" />
            </span>
            <p className="mt-4 text-lg lg:text-2xl">
              Tyle <strong>ofert</strong> przygotowaliśmy
            </p>
          </div>
          <div
            className={`flex w-full cursor-pointer flex-col items-center rounded-3xl px-4 py-6`}
          >
            <img src={ConsultationsSvg} alt="" />
            <span className="relative mt-4 text-6xl font-black text-secondary">
              1279
              <img src={UnderscoreSvg} alt="" />
            </span>
            <p className="mt-4 text-lg lg:text-2xl">
              Tyle <strong>konsultacji</strong> przeprowadziliśmy
            </p>
          </div>
          <div
            className={`flex w-full cursor-pointer flex-col items-center rounded-3xl px-4 py-6`}
          >
            <img src={InsuredSvg} alt="" />
            <span className="relative mt-4 text-6xl font-black text-secondary">
              3287
              <img src={UnderscoreSvg} alt="" />
            </span>
            <p className="mt-4 text-lg lg:text-2xl">
              Tyle <strong>ubezpieczeń</strong> porównaliśmy
            </p>
          </div>
        </div>

        <div className=" w-full text-center">
          <Button
            visualType="primary"
            className="!py-4 uppercase"
            to={config.CALC_URL}
          >
            ZAMÓW KONSULTACJĘ
          </Button>
        </div>
      </div>
    </div>
  );
};
