import { useState, useEffect, RefObject, useCallback } from 'react';

export const useCollapse = (
  isOpened: boolean,
  ref: RefObject<HTMLDivElement>
) => {
  const [isOpen, setIsOpen] = useState(isOpened);
  const [height, setHeight] = useState('0px');

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
    setHeight(isOpen ? '0px' : `${ref.current?.scrollHeight}px`);
  }, [isOpen, ref]);

  useEffect(() => {
    if (ref?.current?.scrollHeight && isOpened) {
      setHeight(`${ref.current?.scrollHeight}px`);
    }
  }, [isOpened]);

  return { isOpen, height, toggle };
};
