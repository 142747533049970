import React, {
  ReactNode,
  useRef,
  useState,
  useEffect,
  MutableRefObject,
} from 'react';
import CaretRightSolid from '@img/caret-right-solid.svg';
import { useCollapse } from '../../hooks/useCollapse';

type CollapseBoxType = {
  icon: string;
  heading: string;
  children: string;
  className?: string;
  isOpened?: boolean;
};

const CollapseBox = ({
  icon,
  heading,
  children,
  className = '',
  isOpened = false,
}: CollapseBoxType) => {
  const content = useRef<HTMLDivElement>(null);

  const { isOpen, height, toggle } = useCollapse(isOpened, content);

  return (
    <div
      className={`flex w-full cursor-pointer flex-col items-center rounded-3xl border-t-[8px] pt-4 shadow-light transition-shadow duration-300 hover:shadow-md  ${className}`}
      onClick={() => toggle()}
      aria-expanded={isOpen}
      aria-controls="collapse-content"
      aria-label="Rozwiń"
    >
      <img src={icon} alt="" className="h-16 w-16" />
      <button className="mt-4 mb-4 flex appearance-none items-center justify-between gap-3 ">
        <h3
          className={`${
            isOpened ? 'text-white' : 'text-text'
          } text-xl font-semibold`}
        >
          {heading}
        </h3>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="6.888"
          height="11.416"
          viewBox="0 0 6.888 11.416"
          className={` ${isOpened ? 'fill-white' : 'fill-text'} ${
            isOpen ? '-rotate-90' : 'rotate-90'
          } text-white transition-transform duration-200`}
        >
          <path
            id="caret-right-solid"
            d="M65.959,96.325l4.592,4.56a1.25,1.25,0,0,1,.337.811,1.2,1.2,0,0,1-.336.812l-4.592,4.56a1.148,1.148,0,0,1-1.251.249A1.074,1.074,0,0,1,64,106.287V97.136a1.146,1.146,0,0,1,1.959-.811Z"
            transform="translate(-64 -95.987)"
          />
        </svg>
      </button>
      <div
        ref={content}
        className={`max-h-0 overflow-hidden rounded-2xl !rounded-tl-none bg-white transition-all duration-500`}
        style={{ maxHeight: height }}
      >
        <p
          dangerouslySetInnerHTML={{ __html: children }}
          className=" animate-fade px-6 py-3"
        ></p>
      </div>
    </div>
  );
};

export default CollapseBox;
