import Button from '@components/button/button';
import Heading from '@components/heading/heading';
import SectionTag from '@components/section-tag/section-tag';
import React from 'react';
import config from '../../../config.json';
import HandsSvg from '@img/hands.svg';
import ShieldSvg from '@img/shield.svg';
import MoneySvg from '@img/money.svg';

export const Benefits = () => {
  return (
    <div className="w-full">
      <div className="container mx-auto px-4 py-6 text-center lg:py-20">
        <div className="flex flex-col items-center justify-center">
          <SectionTag
            tag="span"
            className="border-white bg-[rgba(255,255,255,0.10)] text-white"
          >
            CO DOBREGO DLA DZIECKA?
          </SectionTag>

          <Heading tag="h2" className="max-w-3xl !leading-snug text-white">
            Jakie <span className="text-primary">korzyści</span> daje
            ubezpieczenie dziecka?
          </Heading>
          <p className="mt-4 max-w-lg text-sm text-white lg:mt-7 lg:text-lg">
            Ubezpieczenie dziecka to świetny sposób na zabezpieczenie jego
            przyszłości. Daje komfort psychiczny, a w trudnych momentach…
            pieniądze, które możesz przeznaczyć na najważniejsze potrzeby.
          </p>
          <div className="mt-4 lg:mt-7"></div>
        </div>
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-3 lg:gap-16">
          <div
            className={`flex w-full flex-col items-center rounded-3xl border-t-[8px] border-t-blue bg-white px-4 py-6 shadow-light transition-all duration-300 hover:scale-105 hover:shadow-md lg:px-14 lg:pt-16`}
          >
            <img src={ShieldSvg} alt="" />
            <p className="mt-4 text-lg lg:text-2xl">
              <strong>Bezpieczeństwo finansowe </strong>w każdej sytuacji
            </p>
          </div>
          <div
            className={`flex w-full flex-col items-center rounded-3xl border-t-[8px] border-t-primary bg-white px-4 py-6 shadow-light transition-all duration-300 hover:scale-105 hover:shadow-md lg:px-14 lg:pt-16`}
          >
            <img src={HandsSvg} alt="" />
            <p className="mt-4 text-lg lg:text-2xl">
              <strong>Ochrona zdrowia i życia</strong> już od narodzin dziecka
            </p>
          </div>
          <div
            className={`flex w-full flex-col items-center rounded-3xl border-t-[8px] border-t-orange bg-white px-4 py-6 shadow-light transition-all duration-300 hover:scale-105 hover:shadow-md lg:px-14 lg:pt-16`}
          >
            <img src={MoneySvg} alt="" />
            <p className="mt-4 text-lg lg:text-2xl">
              <strong>Oszczędności</strong> dla dziecka, gdy wkroczy w dorosłość
            </p>
          </div>
        </div>

        <div className="mt-16 w-full text-center">
          <Button
            visualType="primary"
            className="!py-4 uppercase"
            to={config.CALC_URL}
          >
            ZAMÓW KONSULTACJĘ
          </Button>
        </div>
      </div>
    </div>
  );
};
