import React from 'react';
import Button from '../../components/button/button';
import HeaderImg from '../../assets/img/hero.png';
import config from '../../../config.json';
import StarUnderscore from '../../assets/img/starry-underscore.svg';
import RdpLogo from '@img/rdp.svg';
import DpLogo from '@img/dp.svg';
import FamilieLogo from '@img/familie.svg';

type Props = {};

const Hero = (props: Props) => {
  return (
    <div className="bg-blueLight relative w-full overflow-hidden py-6 lg:py-14">
      <div className="container relative z-10 mx-auto px-4">
        <div className="md:max-w-3xl">
          <div className="flex items-center lg:flex-col lg:items-start">
            <h1 className="text-left text-[32px] font-black leading-snug text-text lg:mt-4 lg:text-left lg:text-6xl lg:leading-tight">
              Zagwarantuj
              <br /> swojemu dziecku
              <br />
              <span className="relative text-secondary">
                nawet 40 000 zł{' '}
                <img
                  src={StarUnderscore}
                  className="-bottom-11 right-0 hidden h-[90px] w-[500px] max-w-max lg:absolute lg:block"
                  width={549}
                />
              </span>{' '}
              na start
            </h1>
          </div>
          <p className="my-5 mt-16 hidden text-2xl font-normal lg:block">
            Zapewnij swojemu dziecku{' '}
            <span className="font-black">dobry start</span> w przyszłość.
          </p>
          <div className="mt-10 flex flex-col gap-y-2 md:text-left lg:mt-0 lg:flex-row">
            <a href={config.CALC_URL} target="_blank">
              <Button className="w-full py-5" visualType="primary">
                UZYSKAJ WYCENĘ UBEZPIECZENIA
              </Button>
            </a>
            <Button
              className="w-full justify-center py-4 text-center"
              visualType="tertiary"
              to={'/ranking-ubezpieczen/'}
            >
              Sprawdź ranking ubezpieczeń
            </Button>
          </div>

          <p className="relative z-10 mt-3 text-base font-normal text-lightGrey lg:mt-10 lg:pr-5">
            Pisali o nas
          </p>
          <div className="grid max-w-lg grid-cols-3">
            <img
              src={RdpLogo}
              alt="Ranking dobrych polis"
              className="opacity-50 grayscale transition-all duration-700 hover:opacity-100 hover:grayscale-0"
            />
            <img
              src={FamilieLogo}
              alt="Ranking dobrych polis"
              className="opacity-30 grayscale transition-all duration-700 hover:opacity-100 hover:grayscale-0"
            />
            <img
              src={DpLogo}
              alt="Ranking dobrych polis"
              className="opacity-50 grayscale transition-all duration-700 hover:opacity-100 hover:grayscale-0"
            />
          </div>
        </div>
        <div className="relative -z-10 mx-auto lg:absolute lg:-right-[260px] lg:top-1/2 lg:-translate-y-1/2 xl:-right-[200px] ">
          <img src={HeaderImg} alt="ubezpieczenie mieszkania" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
