import Button from '@components/button/button';
import React from 'react';
import config from '../../../config.json';
import KidImg from '@img/kid.png';
import SectionTag from '@components/section-tag/section-tag';

export function GetMore() {
  return (
    <div className="relative w-full overflow-hidden bg-transparent py-6 pt-10 lg:py-14">
      <div className="container relative z-10 mx-auto grid grid-cols-1 px-4 lg:grid-cols-2">
        <div className="">
          <SectionTag tag="span">CO MUSISZ WIEDZIEĆ?</SectionTag>
          <h2 className="mt-4 text-left text-[32px] font-black leading-snug text-text lg:text-left lg:text-[42px] lg:leading-tight">
            Dowiedz się więcej o&nbsp;
            <span className="relative text-secondary">
              ubezpieczeniach dla dzieci
            </span>
          </h2>
          <p className="mt-6 text-lg">
            Jeżeli chcesz dowiedzieć się więcej o ubezpieczeniach dla dzieci,
            przejdź do poradników. Nasi specjaliści odkryją przed Tobą świat
            ubezpieczeń, wyjaśnią skomplikowane pojęcia, a przede wszystkim
            pomogą świadomie zatroszczyć się o przyszłość Twojego dziecka.
          </p>
          <div className="mt-6 flex flex-col gap-y-2 md:text-left lg:flex-row">
            <Button
              to="/poradniki/"
              className="w-full py-4"
              visualType="primary"
            >
              PRZEJDŹ DO PORADNIKA
            </Button>
          </div>
        </div>
        <div className="relative z-50 flex justify-center">
          <img src={KidImg} alt="ubezpieczenie mieszkania" />
        </div>
      </div>
    </div>
  );
}
