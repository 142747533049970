import React from 'react';
import SectionTag from '../../components/section-tag/section-tag';
import Heading from '../../components/heading/heading';
import Button from '@components/button/button';
import config from '../../../config.json';
import SliderBox from '@components/collapse-box/collapse-box';
import Savings from '@img/oszczednosci.svg';
import Knowladge from '@img/wiedza.svg';
import Activity from '@img/aktywnosc.svg';
import Health from '@img/zdrowie.svg';
import Education from '@img/wyksztalcenie.svg';
import Safty from '@img/bezpieczenstwo.svg';
import Start from '@img/start-w-przyszlosc.png';
type Props = {};

const collapseItems = [
  {
    heading: 'Oszczędności',
    icon: Savings,
    children:
      'Oszczędzając, np. 20 000 zł na przyszłość dla dziecka pomożesz mu zrealizować jego cele i zapewnisz bezpieczeństwo finansowe, gdy będzie stawiał pierwsze kroki w dorosłość.',
    className: 'border-t-orange bg-white',
    isOpen: false,
  },
  {
    heading: 'Wiedza',
    icon: Knowladge,
    children:
      'Szkoła to nie wszystko. Edukacja finansowa dziecka od najmłodszych lat pozwoli na wychowanie zaradnego, świadomego wartości pieniędzy młodego człowieka.',
    className: 'border-t-violet bg-white',
    isOpen: false,
  },
  {
    heading: 'Aktywność',
    icon: Activity,
    children:
      'Aktywność fizyczna, kreatywne hobby – wspierając dziecko w jego pasjach, kreujesz w nim <strong class="text-blue">proaktywną postawę</strong> i dajesz <strong class="text-blue">poczucie sprawczości</strong>.',
    className: 'border-t-blue bg-blue',
    isOpen: true,
  },
  {
    heading: 'Zdrowie',
    icon: Health,
    children:
      'Zdrowe dziecko, to dziecko pełne energii. Dbając o profilaktykę zdrowia, zapewniasz mu <strong class="text-red">dobre samopoczucie</strong> i szansę na <strong class="text-red">długie życie</strong>.',
    className: 'border-t-red bg-red',
    isOpen: true,
  },
  {
    heading: 'Wykształcenie',
    icon: Education,
    children:
      'Prestiżowa szkoła, zajęcia pozalekcyjne czy studia na renomowanym uniwersytecie to nie koszty, to inwestycja i szansa dla Twojego dziecka na dobrze płatną pracę.',
    className: 'border-t-navy bg-white',
    isOpen: false,
  },
  {
    heading: 'Bezpieczeństwo',
    icon: Safty,
    children:
      'Ochrona życia i zdrowia to nie fanaberia. Zabezpieczając dziecko na wypadek zdarzeń losowych, gwarantujesz mu ochronę finansową, a sobie komfort psychiczny.',
    className: 'border-t-primary bg-white',
    isOpen: false,
  },
];

const GoodStart = (props: Props) => {
  return (
    <div className="w-full bg-main">
      <div className="container mx-auto px-4 py-6 pb-10 text-center lg:py-20">
        <div className="flex flex-col items-center justify-center">
          <SectionTag tag="span">PEWNY START W PRZYSZŁOŚĆ</SectionTag>

          <Heading tag="h2" className="max-w-2xl !leading-snug">
            Jak zatroszczyć się o{' '}
            <span className="text-secondary">dobry start</span> w przyszłość
            dziecka?
          </Heading>
        </div>
        <div className="grid grid-cols-1 gap-y-8 lg:grid-cols-4">
          <div className="order-2 flex flex-col gap-y-8 lg:order-1">
            {collapseItems.slice(0, 3).map((item, index) => {
              return (
                <SliderBox
                  key={index}
                  className={item.className}
                  heading={item.heading}
                  icon={item.icon}
                  isOpened={item.isOpen}
                >
                  {item.children}
                </SliderBox>
              );
            })}
          </div>
          <div className="order-1 flex w-full  flex-col items-center lg:order-2 lg:col-span-2 ">
            <p className="mt-4 max-w-lg px-4 text-center text-lg lg:mt-7">
              Nie ma rodziców idealnych – całe szczęście! – ważne, by być
              wystarczająco dobrym. Dowiedz się więcej o tym, jakie elementy
              wpływają na wychowanie świadomego, zaradnego, a przede wszystkim
              szczęśliwego młodego człowieka.
            </p>
            <img src={Start} alt="" />
          </div>
          <div className="order-3 flex flex-col gap-y-8">
            {collapseItems.slice(3, 6).map((item, index) => {
              return (
                <SliderBox
                  key={index}
                  className={item.className}
                  heading={item.heading}
                  icon={item.icon}
                  isOpened={item.isOpen}
                >
                  {item.children}
                </SliderBox>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoodStart;
