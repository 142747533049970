import React from 'react';
import SectionTag from '../../components/section-tag/section-tag';
import Heading from '../../components/heading/heading';
import Step1 from '@img/step1.svg';
import Step2 from '@img/step2.svg';
import Step3 from '@img/step3.svg';
import Step4 from '@img/step4.svg';
import ArrowDashed from '@img/arrow-dashed.svg';
import Button from '@components/button/button';
import config from '../../../config.json';

type Props = {};

const FewSteps = (props: Props) => {
  return (
    <div className="container mx-auto px-4 py-6 text-center lg:py-20">
      <div className="flex flex-col items-center justify-center">
        <SectionTag tag="span">TO TYLKO KILKA KROKÓW</SectionTag>

        <Heading tag="h2" className="max-w-2xl !leading-snug">
          Pomożemy Ci znaleźć{' '}
          <span className="text-secondary">najlepsze ubezpieczenie</span> dla
          Twojego dziecka
        </Heading>
        <p className="mt-4 max-w-lg text-lg lg:mt-7">
          Korzystając z porównywarki masz pewność, że wybierzesz najtańszą i
          najkorzystniejszą dla siebie ofertę.
          <br />
          <span className="max-w-3xl text-lg font-bold">
            Za darmo i bez żadnych zobowiązań.
          </span>
        </p>
        <div className="mt-4 lg:mt-7"></div>
      </div>
      <div className="mt-16 grid grid-cols-1 gap-4 lg:grid-cols-4">
        <div className="relative">
          <div className="relative z-20 flex w-full items-center rounded-3xl border-b-8 border-b-blue bg-white py-2 shadow-pinkLight">
            <img
              src={Step1}
              alt=""
              className="relative -ml-6 scale-75 lg:-ml-2 lg:scale-100"
            />
            <p className="px-3 text-left text-lg lg:px-2 lg:text-xl xl:px-6">
              Wskaż osobę, okres i <strong>cel oszczędzania</strong>
            </p>
          </div>
          <img
            src={ArrowDashed}
            alt=""
            className="absolute -top-6 left-20 z-10 hidden lg:block"
          />
        </div>
        <div className="relative lg:mt-16">
          <div className="relative z-20 flex w-full items-center rounded-3xl border-b-8 border-b-violet bg-white py-2 shadow-pinkLight">
            <img
              src={Step2}
              alt=""
              className="relative -ml-6 scale-75 lg:-ml-2 lg:scale-100"
            />
            <p className="px-3 text-left text-lg lg:px-2 lg:text-xl xl:px-6">
              Porównaj <strong>12&nbsp;propozycji</strong> 4&nbsp;różnych
              towarzystw
            </p>
          </div>
          <img
            src={ArrowDashed}
            alt=""
            className="absolute -bottom-6 left-20 z-10 hidden scale-y-[-1] lg:block"
          />
        </div>
        <div className="relative">
          <div className="relative z-20 flex w-full items-center rounded-3xl border-b-8 border-b-navy bg-white py-2 shadow-pinkLight">
            <img
              src={Step3}
              alt=""
              className="relative -ml-6 scale-75 lg:-ml-2 lg:scale-100"
            />
            <p className="px-3 text-left text-lg lg:px-2 lg:text-xl xl:px-6">
              Wybierz <strong>najlepszy plan</strong> ochrony
              i&nbsp;oszczędzania
            </p>
          </div>
          <img
            src={ArrowDashed}
            alt=""
            className="absolute -top-6 left-20 z-10 hidden lg:block"
          />
        </div>
        <div className="relative lg:mt-16">
          <div className="relative z-20 flex w-full items-center rounded-3xl border-b-8 border-b-red bg-white py-2 shadow-pinkLight">
            <img
              src={Step4}
              alt=""
              className="relative -ml-6 scale-75 lg:-ml-2 lg:scale-100"
            />
            <p className="px-3 text-left text-lg lg:px-2 lg:text-xl xl:px-6">
              Podpisz umowę i <strong>realizuj swój cel</strong>
            </p>
          </div>
        </div>
      </div>
      <div className="mt-16 w-full text-center">
        <Button
          visualType="primary"
          className="!py-4 uppercase"
          to={config.CALC_URL}
        >
          ZAMÓW KONSULTACJĘ
        </Button>
      </div>
    </div>
  );
};

export default FewSteps;
