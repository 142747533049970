import React from 'react';
import InfoSvg from '@img/circle-info-solid.svg';
import ReactTooltip from 'react-tooltip';
import Button from '@components/button/button';
import config from '../../../config.json';

type Props = {};

export const Calculator = (props: Props) => {
  const [years, setYears] = React.useState(0);
  const [contribution, setContribution] = React.useState(450);
  const [kindOfSaving, setKindOfSaving] = React.useState('');
  const [savings, setSavings] = React.useState(0);
  const [calcUrl, setCalcUrl] = React.useState(config.CALC_URL);

  const yearsSelect = [10, 15, 20, 25];

  const kindsOfSaving = [
    'gwarantowane oszczędności',
    'inwestowanie konserwatywne',
    'inwestowanie zrównoważone',
  ];

  const rangeConfig = {
    min: 79,
    max: 1000,
  };

  React.useEffect(() => {
    const savingSchema = {
      'gwarantowane oszczędności': { name: 'guaranteed_savings', factor: 1 },
      'inwestowanie konserwatywne': { name: 'conservative_investing', factor: 1.1 },
      'inwestowanie zrównoważone': { name: 'sustainable_investing', factor: 1.2 }
    };

    let params: any = {
      insuranceLength: years.toString(),
      maxInsurance: contribution,
      savingForm: (kindOfSaving ? savingSchema[kindOfSaving as keyof typeof savingSchema].name : null)
    };

    params = Object.fromEntries(Object.entries(params).filter(([_, v]) => v));

    const queryString = encodeURIComponent(JSON.stringify(params));

    setCalcUrl(`${config.CALC_URL}?psv=${queryString}`)

    if (!years || !contribution) {
      return;
    };

    const totalSavings = Math.round(12 * contribution * years * 0.95 * (kindOfSaving ? savingSchema[kindOfSaving as keyof typeof savingSchema].factor : 1));

    setSavings(totalSavings.toLocaleString('pl'));
  }, [years, kindOfSaving, contribution])

  const handlePsvLink = (e) => {
    if (typeof window !== 'undefined') {
      e.stopPropagation();
      window.location = calcUrl;
    }
  }

  return (
    <div className="w-full rounded-3xl border-t-8 border-primary bg-white px-4 py-7  shadow-pink lg:px-16">
      <ReactTooltip className="!bg-text !text-white" />
      <h2 className="mx-auto max-w-[950px] text-center text-2xl font-bold md:text-[42px] md:leading-[58px]">
        Sprawdź, jak sprawnie możesz stworzyć kapitał na przyszłość swojego
        dziecka
      </h2>
      <div className="flex flex-col gap-x-16 md:mt-4 lg:flex-row">
        <div className="mt-7">
          <label htmlFor="years">Przez ile lat chcesz oszczędzać?</label>
          <div className="mt-2 flex gap-x-4">
            {yearsSelect.map((year, i) => (
              <div className="group relative h-9 w-20" key={i}>
                <input
                  type="radio"
                  value={year}
                  name={`year-${year}`}
                  id={`year-${year}`}
                  onChange={(e) => setYears(Number(e.target.value))}
                  checked={years === year}
                  className="mr-2 h-full w-full cursor-pointer appearance-none rounded-full border border-text transition-colors checked:border-text checked:bg-text hover:border-secondary checked:hover:border-text"
                />
                <label
                  htmlFor={`year-${year}`}
                  className={`absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 cursor-pointer transition-colors  ${years === year
                    ? 'text-white'
                    : 'text-text group-hover:text-secondary'
                    }`}
                >
                  {year}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full">
          <div className="mb-3 mt-4 flex flex-col justify-between lg:mt-0 lg:flex-row lg:items-end">
            <label className="flex">
              Wybierz kwotę składki:{' '}
              <img
                src={InfoSvg}
                alt=""
                className="inline pl-3"
                data-tip="Wpisz kwotę <br/>lub przesuń suwakiem"
                data-multiline={true}
                data-c
                data-place="bottom"
              />
            </label>
            <div className="mr-auto mt-2 flex items-end rounded-2xl border border-gray-200 px-3 py-2 lg:mr-0 lg:mt-0">
              <input
                value={contribution}
                onChange={(e) => setContribution(Number(e.target.value))}
                min={rangeConfig.min}
                max={rangeConfig.max}
                className="h-[56px] w-[140px] appearance-none border-none p-1 text-[56px] font-black leading-none outline-none"
              />
              <span className="text-base">zł</span>
            </div>
          </div>
          <input
            type="range"
            min={rangeConfig.min}
            max={rangeConfig.max}
            value={contribution}
            onChange={(e) => setContribution(Number(e.target.value))}
            className="w-full appearance-none rounded-2xl border border-none"
            id="contribution"
            style={{
              background: `linear-gradient(to right, #100D49 ${((contribution - rangeConfig.min) * 100) /
                (rangeConfig.max - rangeConfig.min)
                }%, #dcdcdc 0px`,
            }}
          />
        </div>
      </div>
      <div className="mt-8">
        <label htmlFor="years">
          Wybierz rodzaj oszczędzania:
          <img
            src={InfoSvg}
            alt=""
            className="inline pl-3"
            data-tip="Zaznacz wybraną opcję"
            data-multiline={true}
            data-place="bottom"
          />
        </label>
        <div className="mt-3 flex flex-col gap-x-4 lg:flex-row">
          {kindsOfSaving.map((saving, i) => (
            <div className="group relative mb-3 h-9 w-full" key={i}>
              <input
                type="radio"
                value={saving}
                name={`year-${saving}`}
                id={`year-${saving}`}
                onChange={(e) => setKindOfSaving(e.target.value)}
                checked={kindOfSaving === saving}
                className="mr-2 h-full w-full cursor-pointer appearance-none rounded-full border border-text transition-colors checked:border-text checked:bg-text hover:border-secondary checked:hover:border-text"
              />
              <label
                htmlFor={`year-${saving}`}
                className={`absolute left-1/2 top-1/2 w-full -translate-y-1/2 -translate-x-1/2 cursor-pointer text-center transition-colors ${kindOfSaving === saving
                  ? 'text-white'
                  : 'text-text group-hover:text-secondary'
                  }`}
              >
                {saving}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="my-6 h-[1px] bg-gray-200"></div>
      <div className="item-center flex flex-col lg:flex-row lg:justify-between">
        <div className="flex flex-col items-center lg:flex-row lg:items-end">
          <p className="mb-3 text-3xl font-bold lg:mb-0">Odłożona kwota:</p>
          <div className="mb-3 flex w-full items-end rounded-2xl border border-gray-200 py-1 px-4 lg:ml-4 lg:mb-0 lg:w-fit lg:px-8">
            <div className="w-full text-center text-5xl font-black leading-none lg:text-[56px]">
              {savings} zł
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <Button visualType="primary" className="py-4" to={calcUrl} onClickCapture={handlePsvLink}>
            PORÓWNAJ OFERTY
          </Button>
        </div>
      </div>
    </div>
  );
};
